import { connect } from 'react-redux'
import PayeeNew from '../../components/PayeeNew'
import { payeeNewSubmit } from './actions'
import convertToOptions from '../../utils/convertToOptions'
import formatSortCode from '../../utils/formatSortCode'
import { showMessage, setShowPayeeMessageFalse } from '../PayeeMessage/actions'
import { flowResetWithData } from '../../store/flow'

let flowData: any = {}
const mapStateToProps = (state, ownProps) => {
  let warning = false
  flowData = state.flow.data || {}
  let entityValue = null
  if (flowData.entity && flowData.entity.value) {
    entityValue = {
      value: flowData.entity.value,
    }
  }
  const twoFactorData = state.twoFactorAuth
  if (twoFactorData.warning) {
    warning = true
  }
  return {
    selectOptions: convertToOptions(state.entities.items),
    showPayeeMessage: state.payeeMessage.showPayeeMessage || false,
    initialBankName: flowData.bankName || '',
    initialValues: {
      name: flowData.name || '',
      sortCode_0: flowData.sortCode_0 || '',
      sortCode_1: flowData.sortCode_1 || '',
      sortCode_2: flowData.sortCode_2 || '',
      accountNumber: flowData.accountNumber || '',
      entity: entityValue,
    },
    successComponentProps: {
      confirmIsVisible: false,
      title: 'Payee details',
      successMessage: 'Payee created',
      needsAuthoriser: warning,
      naMessage: 'Payee initiated',
      successLinks: [
        {
          text: 'Back to my payees',
          to: '/payments/payees',
        },
      ],
      details: [
        {
          descriptor: 'Payee name',
          title: flowData.name,
        },
        {
          descriptor: 'Sort code',
          title: flowData.sortCode && formatSortCode(flowData.sortCode),
        },
        {
          descriptor: 'Account number',
          title: flowData.accountNumber,
        },
      ],
    },
  }
}

const mapDispatchToProps = (dispatch) => ({
  onGoBackClick: (e) => {
    e.preventDefault()
    dispatch(flowResetWithData(flowData))
  },
  payeeNewSubmit,
  onPayeeButtonClick: () => {
    dispatch(showMessage())
  },

  setShowPayeeMessageFalse: () => {
    dispatch(setShowPayeeMessageFalse())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PayeeNew)
