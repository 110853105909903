import formatMoney from '../formatMoney'
import formatSortCode from '../formatSortCode'

interface Datainterface {
  reference: string
  when: string
  currency: string
  amount: string
  to: {
    accountNumber: string
    sortCode: string
    title: string
  }
  from: {
    accountNumber: string
    sortCode: string
    title: string
  }
  repeatsUntil: string
  repeatFreq: string | number
}
export default (d: Datainterface) => {
  const refBlock = d.reference
    ? [
        {
          descriptor: 'Reference',
          title: d.reference,
        },
      ]
    : []

  const recurringBlock = d.repeatFreq
    ? [
        {
          descriptor: 'Recurring',
          title: `Pay ${d.repeatFreq} until ${d.repeatsUntil}`,
        },
      ]
    : []

  return [
    {
      descriptor: 'From account',
      title: d.from.title,
      extras: [`${formatSortCode(d.from.sortCode)} ${d.from.accountNumber}`],
    },
    {
      descriptor: 'Payee',
      title: d.to.title,
      extras: [`${formatSortCode(d.to.sortCode)} ${d.to.accountNumber}`],
    },
    {
      descriptor: 'Amount',
      title: formatMoney(d.amount, d.currency),
    },
    {
      descriptor: 'When',
      title: d.when,
    },
    ...refBlock,
    ...recurringBlock,
  ]
}
