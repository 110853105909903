import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import BottomError from '../Forms/BottomError'
import PayeeFields from '../../containers/PayeeFields'
import SubmitCancel from '../Forms/SubmitCancel'

type FormProps = {
  handleSubmit?: () => void // todo - was mandatory, made optional due to the calling index
  payeeSubmit?: () => void // todo - was mandatory, made optional due to the calling index
  initialBankName?: string // todo - was mandatory, made optional due to the calling index
  error?: string
  invalid?: boolean
  submitting?: boolean
}

const Form = ({
  handleSubmit,
  payeeSubmit,
  error,
  invalid,
  submitting,
  initialBankName,
}: InjectedFormProps<any> & FormProps) => (
  <form onSubmit={handleSubmit(payeeSubmit)}>
    <fieldset className='narrow'>
      <PayeeFields formName='payeeEdit' initialBankName={initialBankName} />

      <BottomError message={error} />
      <SubmitCancel
        submitDisabled={invalid}
        submitText='Update'
        submitting={submitting}
        cancelText='Discard changes'
        cancelTo='/payments/payees'
      />
    </fieldset>
  </form>
)

export default reduxForm<any, FormProps>({
  form: 'payeeEdit',
})(Form)
