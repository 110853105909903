import * as c from '../../constants'

export interface IPayeeMessageState {
  showPayeeMessage: boolean
}

export default function payeeMessageReducer(
  state: IPayeeMessageState = { showPayeeMessage: false },
  action
): IPayeeMessageState {
  switch (action.type) {
    case c.SHOW_PAYEE_MESSAGE: {
      return {
        ...state,
        showPayeeMessage: !state.showPayeeMessage,
      }
    }
    case c.SET_SHOW_PAYEE_MESSAGE_FALSE: {
      return {
        ...state,
        showPayeeMessage: false,
      }
    }
    default:
      return state
  }
}
