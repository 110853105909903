import React from 'react'
import { Field } from 'redux-form'
import SingleField from '../SingleField'
import SortCode from '../SortCode'
import * as v from '../../../utils/forms/validators'
import * as n from '../../../utils/forms/normalizers'
import s from './styles.css'

type PaymentMakeNewPayeeProps = {
  onSortCodeChange: () => void
  bankName?: string
}

const PaymentMakeNewPayee = ({ onSortCodeChange, bankName }: PaymentMakeNewPayeeProps) => (
  <div>
    <Field
      name='name'
      label="Payee's name"
      component={SingleField}
      props={{ autoFocus: true }}
      type='text'
      validate={v.payeeName}
      validationMessages={{
        maxPayeeNameAN: 'Must be 35 alphanumeric characters or less',
        required: 'What is the name of the payee?',
        onlyPermittedChars: 'Allowed characters: A-Z 0-9 $ @ _ #',
      }}
    />

    <div className={s.row}>
      <Field
        label='Sort code'
        name='sortCode'
        component={SingleField}
        InputComponent={SortCode}
        validate={v.sortCode}
        onChange={onSortCodeChange}
        validateType='onBlur'
        validationMessages={{
          required: 'What is the sort code',
          onlyLength6: 'Sort code should look like this: 12 - 34 - 56',
          badSortCode: 'Sort code is not recognised, please check',
          noFastPayments: 'This bank does not currently accept faster payments',
        }}
      />
      {/* eslint-disable-next-line react/no-danger */}
      {bankName && <p className={s.bankName} dangerouslySetInnerHTML={{ __html: bankName }} />}
    </div>

    <Field
      label='Account number'
      name='accountNumber'
      type='text'
      component={SingleField}
      validate={v.accountNo}
      normalize={n.accountNo}
      validationMessages={{
        required: 'Please enter your sort code',
        onlyLength8: 'The account number should be 8 digits',
      }}
    />
  </div>
)

export default PaymentMakeNewPayee
