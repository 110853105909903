import { connect } from 'react-redux'
import PaymentMakeNewPayee from '../../components/PaymentMakeNewPayee'
import { paymentMakePayeeSubmit, paymentMakeNewPayeeHide } from '../PaymentMake/actions'
import { showMessage } from '../PayeeMessage/actions'

const mapStateToProps = (state) => ({
  showPayeeMessage: state.payeeMessage.showPayeeMessage,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  payeeSubmit: paymentMakePayeeSubmit,
  onViewPayeesClick: () => {
    dispatch(paymentMakeNewPayeeHide())
  },
  onPayeeMessageClick: () => {
    dispatch(showMessage())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMakeNewPayee)
