import * as c from '../../constants'

export function showMessage() {
  return {
    type: c.SHOW_PAYEE_MESSAGE,
  }
}

export function setShowPayeeMessageFalse() {
  return {
    type: c.SET_SHOW_PAYEE_MESSAGE_FALSE,
  }
}
