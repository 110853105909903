import { dontDuplicate } from '../utils/routerBugWorkaround'

export default (store) => ({
  path: 'edit/:id',
  getComponent(nextState, cb) {
    require.ensure(
      [],
      (require) => {
        const PayeeEdit = require('../containers/PayeeEdit').default
        const { paymentMakeReset } = require('../containers/PaymentMake/actions')

        dontDuplicate(() => {
          store.dispatch(paymentMakeReset())
        })

        cb(null, PayeeEdit)
      },
      'payee-edit'
    )
  },
})
