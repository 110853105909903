/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import { call, put, takeLatest, select, delay, apply } from 'redux-saga/effects'
import { Parser } from 'json2csv'
import moment from 'moment'
import request from '../../utils/request'
import humanError from '../../utils/humanError'
import saveFile from '../../utils/saveFile'
import * as c from '../../constants'
import * as a from './actions'
import getAccount from '../../selectors/getAccount'
import {
  getLastQueriedStartDate,
  getSelectedDates,
  getTransactionFilters,
  getVisibleTransactions,
  getIsFiltered,
  saveTransactions,
  getFilterShowMoreCount,
  getCurrentDateRange,
} from './selectors'
import { modalErrorShow } from '../../store/ui'
import { SupportedCurrencies } from '../../models/enum'

export function* fetchMore(action) {
  const p = action.payload
  const maskedId = p.id

  let startDate, endDate
  let stop = false
  let loopOnce = false
  let gotAll = false
  let earliestStartDate
  let shortDays = 30
  let longDays = 365
  let dateRange = ''
  let baseUrl

  yield put(a.transactionsFetchStart(maskedId))
  const account = yield select(getAccount, maskedId)
  let openingDate = moment(account.openingDate, 'DD/MM/YYYY')
  const partyId = account.customerId

  // If account is a loan or termDeposit, we know the dates to search
  if (account.accountType === 'LON' || account.type === 'TD') {
    endDate = moment().startOf('day').format('YYYY-MM-DD')
    startDate = moment(account.details.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    loopOnce = true
    gotAll = true
    dateRange = `searchBy=SPD&fromDate=${startDate}&toDate=${endDate}`
  } else {
    earliestStartDate = yield select(getLastQueriedStartDate, maskedId)
  }

  if (account.type === 'MO') {
    openingDate = moment(account.details.startDate, 'DD/MM/YYYY')
    shortDays = 730
    longDays = 730
  }

  try {
    let details
    // Skip over if we already have end and start date
    if (!endDate && !startDate) {
      // If no date to start with, get it using last N transactions
      if (!earliestStartDate) {
        const nonce = yield call(request, {
          id: c.requestNonce,
          url: 'v1/session/nonce',
        })
        baseUrl = `/cz/v1/accounts/demandDeposit/${account.accountId}`
        details = yield call(request, {
          id: c.requestLastTransaction,
          url: `${baseUrl}/transactions?searchBy=LNT&noOfTransactions=1`,
          nonce,
          partyId,
        })
        if (details) {
          endDate = details.endDate
          startDate = details.startDate
          dateRange = `searchBy=SPD&fromDate=${startDate}&toDate=${endDate}`
        } else {
          stop = true
          gotAll = true
        }
      } else {
        endDate = earliestStartDate.clone().format('YYYY-MM-DD')
        startDate = earliestStartDate.clone().subtract(shortDays, 'days').format('YYYY-MM-DD')
      }

      // Dont get anything if the last date got is before the opening date
      if (earliestStartDate && openingDate && earliestStartDate.isSameOrBefore(openingDate)) {
        stop = true
        gotAll = true
      }
    }

    if (!stop) {
      let items = false
      let details = ''
      // Keep making requests until we either get something or end date is
      // before opening date
      while (!items || items.length === 0) {
        if (account.group === 'lending') {
          baseUrl = `/v1/accounts/loan/${account.accountId}`
          const nonce = yield call(request, {
            id: c.requestNonce,
            url: 'v1/session/nonce',
          })
          details = yield call(request, {
            id: c.requestLoanTransactions,
            url: `${baseUrl}/transactions?${dateRange}`,
            nonce,
            partyId,
          })
          items = details.items
        } else if (account.type === 'TD') {
          baseUrl = `/v1/accounts/deposit/${account.accountId}`
          const nonce = yield call(request, {
            id: c.requestNonce,
            url: 'v1/session/nonce',
          })
          details = yield call(request, {
            id: c.requestTermDepositTransactions,
            url: `${baseUrl}/transactions?${dateRange}`,
            nonce,
            partyId,
          })
          items = details.items
        } else {
          startDate = moment(startDate).format('YYYY-MM-DD')
          endDate = moment(endDate).format('YYYY-MM-DD')
          baseUrl = `/cz/v1/accounts/demandDeposit/${account.accountId}`
          dateRange = `searchBy=SPD&fromDate=${startDate}&toDate=${endDate}`
          const nonce = yield call(request, {
            id: c.requestNonce,
            url: 'v1/session/nonce',
          })
          details = yield call(request, {
            id: c.requestTransactions,
            url: `${baseUrl}/transactions?${dateRange}`,
            nonce,
            partyId,
          })
          items = details.items
        }

        // Only do request once where we know we're getting everything
        if (loopOnce) break

        // Increment the date range to a year after the first request
        // if there are no items and we'll be looping again
        if (items.length === 0) {
          endDate = moment(startDate, 'YYYY-MM-DD').subtract(1, 'day')
          startDate = moment(startDate, 'YYYY-MM-DD').subtract(longDays, 'days')
        }

        // Break out if end date is before opening date
        if (moment(endDate, 'YYYY-MM-DD').isSameOrBefore(openingDate) && !loopOnce) {
          gotAll = true
          break
        }
      }

      if (!stop && items.length > 0) {
        yield put(a.transactionsAddMore(maskedId, items, startDate))
        yield put(a.transactionsMergeMore(p.id, details.items))
      }
    }
    // Dispatch this action when we know we've got absolutely everything
    if (gotAll || moment(startDate, 'YYYY-MM-DD').isSameOrBefore(openingDate)) {
      yield put(a.transactionsGotAll(maskedId))
    }
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(a.transactionsFetchFail(p.id, message))
  }
}

export function* fetchLatest(action) {
  const p = action.payload
  const maskedId = p.id
  const account = yield select(getAccount, maskedId)
  const partyId = account.customerId
  let items = false
  let details = ''
  let dates = {
    startDate: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
    endDate: moment().add(1, 'days').startOf('day').format('YYYY-MM-DD'),
  }
  let txnDates = `/transactions?searchBy=SPD&fromDate=${dates.startDate}&toDate=${dates.endDate}`
  try {
    if (account.group === 'lending') {
      const response1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const accNumber = `/v1/accounts/loan/${account.accountId}`
      details = yield call(request, {
        id: c.requestLoanTransactions,
        url: `${accNumber}${txnDates}`,
        nonce: response1,
        lastNTransactions: 1,
        partyId,
      })
      items = details.items
    } else if (account.type === 'TD') {
      const response1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const accNumber = `/v1/accounts/deposit/${account.accountId}`
      details = yield call(request, {
        id: c.requestTermDepositTransactions,
        url: `${accNumber}${txnDates}`,
        nonce: response1,
        partyId,
      })
      items = details.items
    } else {
      let nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const accNumber = `/cz/v1/accounts/demandDeposit/${account.accountId}`
      let details = yield call(request, {
        id: c.requestLastTransaction,
        url: `${accNumber}/transactions?searchBy=LNT&noOfTransactions=1`,
        nonce,
        partyId,
      })
      if (details) {
        dates = {
          startDate: moment(details.endDate, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD'),
          endDate: details.endDate,
        }
        txnDates = `/transactions?searchBy=SPD&fromDate=${dates.startDate}&toDate=${dates.endDate}`
      }
      nonce = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      details = yield call(request, {
        id: c.requestTransactions,
        url: `${accNumber}${txnDates}`,
        nonce,
        partyId,
      })
      items = details.items
    }
    yield put(a.transactionsMergeLatest(p.id, items))
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

export function* fetchDates(action) {
  const p = action.payload
  try {
    const maskedId = p.id
    const account = yield select(getAccount, maskedId)
    const partyId = account.customerId

    yield put(a.transactionsFetchStart(maskedId))

    const earliestStartDate = yield select(getLastQueriedStartDate, maskedId)
    const dates = yield select(getSelectedDates, 'transactions')
    const startDate = moment(dates[0])
    const endDate = moment(dates[1])
    const formattedStartDate = moment(dates[0]).format('YYYY-MM-DD')
    const formattedEndDate = moment(dates[1]).format('YYYY-MM-DD')
    const baseUrl = '/cz/v1/accounts/demandDeposit'
    const dateRange = `searchBy=SPD&fromDate=${formattedStartDate}&toDate=${formattedEndDate}`
    yield put(a.rTransactionsFilterDates(maskedId, startDate, endDate))

    if (earliestStartDate) {
      if (earliestStartDate.isSameOrBefore(startDate)) {
        yield put(a.transactionsFetchStop(maskedId))
      } else if (endDate.isSameOrAfter(earliestStartDate)) {
        const response1 = yield call(request, {
          id: c.requestNonce,
          url: 'v1/session/nonce',
        })
        const details = yield call(request, {
          id: c.requestTransactions,
          url: `${baseUrl}/${account.accountId}/transactions?${dateRange}`,
          nonce: response1,
          partyId,
        })
        yield put(a.transactionsAddMore(maskedId, details.items, moment(startDate)))
        yield put(a.transactionsMergeLatest(p.id, details.items))
      } else {
        const response1 = yield call(request, {
          id: c.requestNonce,
          url: 'v1/session/nonce',
        })
        const details = yield call(request, {
          id: c.requestTransactions,
          url: `${baseUrl}/${account.accountId}/transactions?${dateRange}`,
          nonce: response1,
          partyId,
        })
        yield put(a.transactionsTempItemsPopulate(maskedId, details.items))
        yield put(a.transactionsMergeLatest(p.id, details.items))
      }
    } else {
      const response1 = yield call(request, {
        id: c.requestNonce,
        url: 'v1/session/nonce',
      })
      const details = yield call(request, {
        id: c.requestTransactions,
        url: `${baseUrl}/${account.accountId}/transactions?${dateRange}`,
        nonce: response1,
        partyId,
      })
      yield put(a.transactionsAddMore(maskedId, details.items, moment(startDate)))
      yield put(a.transactionsMergeLatest(p.id, details.items))
    }
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(a.transactionsFetchFail(p.id, message))
  }
}

export function* keywordSearch(action) {
  const p = action.payload
  try {
    const account = yield select(getAccount, p.id)
    const startDate = moment(account.openingDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    const toDate = moment().format('YYYY-MM-DD')
    const filters = yield select(getTransactionFilters)
    const searchTerm = filters.search
    const baseUrl = '/cz/v1/accounts/demandDeposit'
    const range = `fromDate=${startDate}&toDate=${toDate}`
    const partyId = account.customerId
    if (!filters.datesStartEnd) {
      if (!searchTerm) {
        yield put(a.transactionsTempItemsClear(p.id))
      } else if (searchTerm.length < 3) {
      } else {
        const response1 = yield call(request, {
          id: c.requestNonce,
          url: 'v1/session/nonce',
        })
        yield put(a.transactionsFetchStart(p.id))
        yield delay(300)
        const description = `searchBy=SPD&${range}&description=${searchTerm}`
        const transactions = yield call(request, {
          id: c.requestTransactions,
          url: `${baseUrl}/${account.accountId}/transactions?${description}`,
          nonce: response1,
          partyId: partyId,
        })
        yield put(a.transactionsTempItemsPopulate(p.id, transactions.items))
      }
    }
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(a.transactionsFetchFail(p.id, message))
  }
}

function extractBalance(item: string): string {
  return item.replace(/[^\d.-]/gi, '').trim()
}

export function extractCurrency(item: string): string {
  const currency = item.replace(/[\d.,-]/gi, '').trim()
  return SupportedCurrencies[currency] || currency
}

export function getCurrencyString(prefix: string, currency: string): string {
  if (currency !== undefined) return `${prefix}${currency}`
  return ''
}

interface DownloadCSVField {
  label: string
  value: string
}
function addBalance(fields: Array<DownloadCSVField>) {
  return [...fields, { label: 'Balance', value: 'balance' }]
}

interface DownloadCSVAction {
  payload: {
    id: string
    type: string
    startDate: string
    endDate: string
  }
}

export function* downloadCSV(action: DownloadCSVAction) {
  try {
    const { id, type, startDate, endDate } = action.payload
    const items = yield select(getVisibleTransactions, id)
    const isFiltered = yield select(getIsFiltered)
    const account = yield select(getAccount, id)
    let fields = [
      { label: 'Date', value: 'date' },
      { label: 'Reference', value: 'title' },
      { label: 'Debit', value: 'debit' },
      { label: 'Credit', value: 'credit' },
    ]

    if (!isFiltered && account.group !== 'lending') {
      fields = addBalance(fields)
    }

    const currency = items[0] && extractCurrency(items[0].debitCredit)

    const parsedAccountingItems = (items || []).map((item) => {
      return {
        date: item.date,
        debitCredit: extractBalance(item.debitCredit),
        title: item.title,
      }
    })

    switch (type) {
      case 'csv': {
        const json2csvParser = new Parser({ fields })
        let csv = yield apply(json2csvParser, json2csvParser.parse, [items])
        const dateTimes = `From ${startDate} To ${endDate}.`
        const formattedAccountTitle = `"${account.title}","","","",""\n`
        const formattedAccount = `"${account.sortCode} ${account.accountNumber}","","","",""\n`
        const dateTimesFormatted = `"${dateTimes}","","","",""\n`
        csv = formattedAccountTitle + formattedAccount + dateTimesFormatted + csv
        yield call(saveFile, csv, `${account.title} (${dateTimes}).csv`, 'csv')
        break
      }
      case 'pdf': {
        yield select(saveTransactions, id, startDate, endDate)
        break
      }
      case 'quickbooks': {
        // Quickbooks file format docs:

        // eslint-disable-next-line max-len
        // https://quickbooks.intuit.com/learn-support/en-uk/help-article/bank-transactions/format-csv-files-excel-get-bank-transactions/L4BjLWckq_GB_en_GB

        // We follow the three column format currently
        const fields = [
          { label: 'Date', value: 'date' },
          { label: 'Title', value: 'title' },
          { label: 'Amount', value: 'debitCredit' },
        ]
        const json2csvParser = new Parser({ fields })
        const csv = yield apply(json2csvParser, json2csvParser.parse, [items])

        yield call(saveFile, csv, `${account.title}.csv`, 'csv')
        break
      }
      case 'sage': {
        // Sage file format docs:
        // https://help.sbc.sage.com/en-gb/accounting/banking/importing-bank-statement.html
        // We follow the three column format currently
        const i = items || []
        const trimmedItems = i.map((item) => ({
          ...item,
          debitCredit: item.debitCredit.replace(/£|,/gi, ''),
        }))
        const fields = [
          { label: 'Date', value: 'date' },
          { label: 'Reference', value: 'title' },
          { label: 'Amount', value: 'debitCredit' },
        ]
        const json2csvParser = new Parser({ fields })
        const csv = yield apply(json2csvParser, json2csvParser.parse, [trimmedItems])

        yield call(saveFile, csv, `${account.title}.csv`, 'csvPlainText')
        break
      }
      case 'xero': {
        // Xero file format docs:
        // https://central.xero.com/s/article/Import-a-CSV-bank-statement#Preparethedatainthefile
        const fields = [
          { label: 'Date', value: 'date' },
          { label: 'Amount', value: 'debitCredit' },
          { label: 'Description', value: 'title' },
        ]
        const json2csvParser = new Parser({ fields })
        const csv = yield apply(json2csvParser, json2csvParser.parse, [parsedAccountingItems])

        yield call(
          saveFile,
          csv,
          `${account.title}_xero${getCurrencyString('_', currency)}.csv`,
          'csv'
        )
        break
      }
    }
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(modalErrorShow(message))
  }
}

// new functions for date filter pagination

export function* fetchDatesOnLoad(action) {
  const p = action.payload
  try {
    const maskedId = p.id
    const account = yield select(getAccount, maskedId)
    const partyId = account.customerId
    yield put(a.updateFilterShowMoreCountZero())

    const dates = yield select(getSelectedDates, 'transactions')
    const showmorecount = yield select(getFilterShowMoreCount, 'transactions')

    const startDate = moment(dates[0])
    const endDate = moment(dates[1])
    // new code
    const diffMonths = endDate.diff(startDate, 'month', true)
    const dateR = {}
    let counter = 0
    let counter2 = 0

    if (diffMonths > 6) {
      while (counter < diffMonths) {
        let tempStartDate = endDate
          .clone()
          .subtract(counter * 1 + 6, 'months')
          .add(1, 'day')
        const tempEndDate = endDate.clone().subtract(counter * 1, 'months')

        if (tempStartDate.isBefore(startDate)) {
          tempStartDate = startDate
        }

        dateR[counter2] = [tempStartDate, tempEndDate]

        counter += 6
        counter2++
      }
    } else {
      dateR['0'] = [startDate, endDate]
    }
    const baseUrl = '/cz/v1/accounts/demandDeposit'

    yield put(a.rTransactionsFilterDates(maskedId, startDate, endDate, diffMonths, dateR))
    yield put(a.transactionsFetchStart(maskedId))

    const key = showmorecount
    const startDateN = dateR[key][0]
    const endDateN = dateR[key][1]
    const startDateFormatted = moment(startDateN).format('YYYY-MM-DD')
    const endDateFormatted = moment(endDateN).format('YYYY-MM-DD')

    const dateRange = `searchBy=SPD&fromDate=${startDateFormatted}&toDate=${endDateFormatted}`
    const response1 = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const details = yield call(request, {
      id: c.requestTransactions,
      url: `${baseUrl}/${account.accountId}/transactions?${dateRange}`,
      nonce: response1,
      partyId,
    })
    yield put(a.transactionsTempItemsPopulate(maskedId, details.items))
    yield put(a.updateFilterShowMoreCount())
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(a.transactionsFetchFail(p.id, message))
  }
}

export function* fetchDatesOnShowMore(action) {
  const p = action.payload
  try {
    const maskedId = p.id
    const account = yield select(getAccount, maskedId)
    const partyId = account.customerId

    yield put(a.transactionsFetchStart(maskedId))

    const filterShowMoreCount = yield select(getFilterShowMoreCount, 'transactions')
    const dateR = yield select(getCurrentDateRange, filterShowMoreCount)
    const baseUrl = '/cz/v1/accounts/demandDeposit'

    const startDateN = dateR[0]
    const endDateN = dateR[1]
    const startDateFormatted = moment(startDateN).format('YYYY-MM-DD')
    const endDateFormatted = moment(endDateN).format('YYYY-MM-DD')

    const dateRange = `searchBy=SPD&fromDate=${startDateFormatted}&toDate=${endDateFormatted}`
    const response1 = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const details = yield call(request, {
      id: c.requestTransactions,
      url: `${baseUrl}/${account.accountId}/transactions?${dateRange}`,
      nonce: response1,
      partyId,
    })

    yield put(a.transactionsTempItemsMerge(maskedId, details.items))
    yield put(a.transactionsFetchStop(maskedId))
    yield put(a.updateFilterShowMoreCount())
  } catch (error) {
    console.error(error)
    const message = yield call(humanError, error)
    yield put(a.transactionsFetchFail(p.id, message))
  }
}

// end new functions

export function* watchTransactions() {
  yield takeLatest(c.TRANSACTIONS_FETCH_MORE, fetchMore)
  yield takeLatest(c.U_TRANSACTIONS_FILTER_DATES, fetchDatesOnLoad)
  yield takeLatest(c.TRANSACTIONS_FETCH_MORE_FILTERS, fetchDatesOnShowMore)
  yield takeLatest(c.TRANSACTIONS_SEARCH, keywordSearch)
  yield takeLatest(c.TRANSACTIONS_FETCH_LATEST, fetchLatest)
  yield takeLatest(c.TRANSACTIONS_DOWNLOAD, downloadCSV)
}
