/* eslint-disable react/style-prop-object */
import React from 'react'
import s from './styles.css'
import StickyTable from '../StickyTable'
import Form from './Form'
import FilterPanel from '../FilterPanel'
import DocOptions from '../DocOptions'
import Button from '../Button'
import ResultsInfo from '../ResultsInfo'
import Tab from '../../containers/TransactionsTab'
import Tabs from '../Tabs'
import TransactionsFiltersForm from '../../containers/TransactionsFiltersForm'
import SuperDatePicker from '../../containers/SuperDatePicker'
import SlideyOrModal from '../../containers/SlideyOrModal'
import MobileFilters from './MobileFilters'
import { SupportedCurrenciesType } from '../../models/enum'

type TransactionsProps = {
  id: string
  browser: {
    [key: string]: {
      [key: string]: string | boolean
    }
  } // todofixed - was object.
  rows: any[]
  headerCols: any[]
  onClickMore: () => void
  onClickMoreFilters: () => void
  onDateClick: () => void
  onSearch: () => void
  onFiltersToggleClick: () => void
  onFiltersClearClick: () => void
  onDatesApply: () => void
  filtersShowing?: boolean
  datePickerShowing?: boolean
  dateRangeText?: string
  startDate: moment.Moment
  endDate: moment.Moment
  onMobileSubmit: () => void
  loadMoreIsVisible?: boolean
  loadMoreIsVisibleFilters?: boolean
  onDatesClearClick: () => void
  isFiltered?: boolean
  title: string
  onPrintClick: () => void
  onDownloadClick: () => void
  resultsState: any // todo - was object.
  filterPanelEnabled?: boolean
  searchText?: string
  clearButtonIsShowing?: boolean
  currency?: SupportedCurrenciesType
  showDownloadButton: boolean
  filters?: any // todo - was object
  dateFilterSelected?: boolean
}

export const Transactions = ({
  rows,
  headerCols,
  browser,
  onClickMore,
  onClickMoreFilters,
  onDateClick,
  id,
  onSearch,
  onFiltersToggleClick,
  onFiltersClearClick,
  onDatesApply,
  filtersShowing,
  datePickerShowing,
  dateRangeText,
  startDate,
  endDate,
  onMobileSubmit,
  loadMoreIsVisible,
  loadMoreIsVisibleFilters,
  onDatesClearClick,
  isFiltered,
  title,
  onPrintClick,
  onDownloadClick,
  resultsState,
  filterPanelEnabled,
  searchText,
  clearButtonIsShowing,
  currency,
  showDownloadButton,
  filters,
  dateFilterSelected,
}: TransactionsProps) => {
  const clearButtonClass = clearButtonIsShowing ? undefined : s.invisible

  return (
    <div>
      <StickyTable
        showPendingTransactions
        rows={rows}
        headerCols={headerCols}
        browser={browser}
        hideResultsState
        hasDrawer={false}
        filters={filters}
      >
        {filterPanelEnabled && (
          <FilterPanel>
            <div className={s.row}>
              <div className={s.col1}>
                <Form
                  searchText={searchText}
                  onSearch={onSearch}
                  dateRangeText={dateRangeText}
                  onDateClick={onDateClick}
                  onDatesClearClick={onDatesClearClick}
                  dateFilterSelected={dateFilterSelected}
                />
              </div>
              <div className={s.col2}>
                <Button
                  onClick={onFiltersClearClick}
                  className={`${s.filtersButton} ${s.clearButton} ${clearButtonClass}`}
                  text='Clear'
                  style='ghost-light'
                  size='expand'
                />
                <Button
                  onClick={onFiltersToggleClick}
                  className={s.filtersButton}
                  text='Show filters'
                  style='ghost-light'
                  size='expand'
                />
              </div>
            </div>
            <SlideyOrModal id='filters' isOpen={filtersShowing} onCloseClick={onFiltersToggleClick}>
              {browser.lessThan.medium ? (
                <MobileFilters
                  onCancelClick={onFiltersToggleClick}
                  onSubmit={onMobileSubmit}
                  handleSubmit={onMobileSubmit} /* todo - was added as MobileFilters requires it */
                  initialStartDate={startDate}
                  initialEndDate={endDate}
                  currency={currency}
                />
              ) : (
                <TransactionsFiltersForm currency={currency} id={id} />
              )}
            </SlideyOrModal>

            <SlideyOrModal id='datePicker' isOpen={datePickerShowing} onCloseClick={onDateClick}>
              {/* This component is initiated in routes/Account.js */}
              <SuperDatePicker
                id='transactions'
                initialStartDate={startDate}
                initialEndDate={endDate}
                onSubmit={onDatesApply}
              />
            </SlideyOrModal>

            <nav className={s.tabsNav}>
              <Tabs>
                <Tab id={id} text='All' filter={undefined} />
                <Tab id={id} text='Debit' filter='D' />
                <Tab id={id} text='Credit' filter='C' />
              </Tabs>
            </nav>
          </FilterPanel>
        )}
        <DocOptions
          title={title}
          isFiltered={isFiltered}
          startDate={startDate}
          endDate={endDate}
          onPrintClick={onPrintClick}
          onDownloadClick={showDownloadButton && onDownloadClick}
          showToolTip={loadMoreIsVisibleFilters}
        />
      </StickyTable>
      {/* eslint-disable-next-line no-nested-ternary */}
      {loadMoreIsVisible ? (
        <div className={s.bottom}>
          <Button
            onClick={onClickMore}
            text='Show More'
            loading={resultsState.state === 'loading'}
          />
        </div>
      ) : loadMoreIsVisibleFilters ? (
        <div className={s.bottom}>
          <Button
            onClick={onClickMoreFilters}
            text='Show More'
            loading={resultsState.state === 'loading'}
          />
        </div>
      ) : (
        <ResultsInfo state={resultsState} />
      )}
    </div>
  )
}

export default Transactions
