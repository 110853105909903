import omit from 'lodash/omit'
import unionWith from 'lodash/unionWith'
import isEqual from 'lodash/isEqual'
import uniqBy from 'lodash/uniqBy'
import moment from 'moment'
import * as c from '../../constants'

// ------------------------------------
// Reducer
// ------------------------------------

export interface ITransactionsState {
  accounts: any
  filters: any
  tempItems: any
  dateFilterSelected: boolean
  diffMonths: number
  filterShowMoreCount: number
  dateRangeR: any
}

const initialState: ITransactionsState = {
  accounts: {},
  filters: {},
  tempItems: false, // todo-ts: used as a boolean or array
  dateFilterSelected: false,
  diffMonths: 0,
  filterShowMoreCount: 0,
  dateRangeR: {},
}

export default function transactionsReducer(state = initialState, action): ITransactionsState {
  const p = action.payload

  switch (action.type) {
    case c.TRANSACTIONS_FETCH_START:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [p.id]: {
            ...state.accounts[p.id],
            isFetching: true,
            failedToFetch: false,
          },
        },
      }
    case c.TRANSACTIONS_FETCH_FAIL:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [p.id]: {
            ...state.accounts[p.id],
            isFetching: false,
            failedToFetch: true,
            errorMessage: p.error.message ? p.error.message : p.error,
            errorCode: typeof p.error === 'object' ? p.error.errorCode : '',
          },
        },
      }
    case c.TRANSACTIONS_FETCH_STOP:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [p.id]: {
            ...state.accounts[p.id],
            isFetching: false,
          },
        },
      }
    case c.TRANSACTIONS_GOT_ALL:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [p.id]: {
            ...state.accounts[p.id],
            isFetching: false,
            gotAll: true,
          },
        },
      }
    case c.TRANSACTIONS_ADD_MORE: {
      let items = state.accounts[p.id] && state.accounts[p.id].items

      if (!items) {
        items = []
      }
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [p.id]: {
            ...state.accounts[p.id],
            earliestStartDate: moment(p.startDate).format('X'),
            isFetching: false,
            gotAll: false,
            items: [...items, ...p.items],
          },
        },
      }
    }
    case c.TRANSACTIONS_MERGE_LATEST: {
      const oldItems = state.accounts[p.id].items
      let newItems = []
      if (Array.isArray(p.items)) {
        newItems = p.items
      } else if (Array.isArray(p.items.items)) {
        newItems = p.items.items
      }
      const items = uniqBy(unionWith(newItems, oldItems, isEqual), 'id')
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [p.id]: {
            ...state.accounts[p.id],
            items,
          },
        },
      }
    }
    case c.TRANSACTIONS_MERGE_MORE: {
      const oldItems = state.accounts[p.id].items
      let newItems = []
      if (Array.isArray(p.items)) {
        newItems = p.items
      } else if (Array.isArray(p.items.items)) {
        newItems = p.items.items
      }
      const items = uniqBy(unionWith(oldItems, newItems, isEqual), 'id')
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [p.id]: {
            ...state.accounts[p.id],
            items,
          },
        },
      }
    }
    case c.TRANSACTIONS_TEMP_ITEMS_POPULATE: {
      const tempItems = p.items

      return {
        ...state,
        accounts: {
          ...state.accounts,
          [p.id]: {
            ...state.accounts[p.id],
            isFetching: false,
          },
        },
        tempItems,
      }
    }
    case c.TRANSACTIONS_TEMP_ITEMS_CLEAR:
      return {
        ...state,
        accounts: {
          ...state.accounts,
          [p.id]: {
            ...state.accounts[p.id],
            isFetching: false,
          },
        },
        tempItems: false,
      }
    case c.TRANSACTIONS_SWITCH_DEBIT_CREDIT: {
      let filters

      if (!p.filter) {
        filters = omit(state.filters, ['debitCreditFlag'])
      } else {
        filters = {
          ...state.filters,
          debitCreditFlag: p.filter,
        }
      }

      return {
        ...state,
        filters,
      }
    }
    case c.TRANSACTIONS_SEARCH: {
      const search = p.searchTerm
      let filters

      if (search !== null) {
        if (search === '') {
          filters = omit(state.filters, ['search'])
        } else if (search.length < 3) {
          return state
        } else if (search !== null) {
          filters = {
            ...state.filters,
            search,
          }
        }

        return {
          ...state,
          filters,
        }
      }
      return state
    }
    case c.TRANSACTIONS_FILTER_AMOUNT_MIN_MAX: {
      let filters

      if (!p.min && !p.max) {
        filters = omit(state.filters, ['amountMinMax'])
      } else {
        filters = {
          ...state.filters,
          amountMinMax: [p.min, p.max],
        }
      }

      return {
        ...state,
        filters,
      }
    }
    case c.TRANSACTIONS_FILTER_TAGS: {
      const tags = []
      let filters

      Object.keys(p.tags).forEach((tag) => {
        if (p.tags[tag]) tags.push(tag)
      })

      if (tags.length === 0) {
        filters = omit(state.filters, ['tags'])
      } else {
        filters = {
          ...state.filters,
          tags,
        }
      }

      return {
        ...state,
        filters,
      }
    }
    case c.R_TRANSACTIONS_FILTER_DATES: {
      let { tempItems } = state

      // If either start or end are different, destroy tempItems
      if (
        !state.filters.datesStartEnd ||
        (tempItems &&
          tempItems.length &&
          (!p.startDate.isSame(state.filters.datesStartEnd[0]) ||
            !p.endDate.isSame(state.filters.datesStartEnd[1])))
      ) {
        tempItems = false
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          datesStartEnd: [p.startDate, p.endDate],
        },
        tempItems,
        dateFilterSelected: true,
        diffMonths: p.diffMonths,
        dateRangeR: p.dateRangeR,
      }
    }
    case c.TRANSACTIONS_CLEAR_DATES: {
      const filters = omit(state.filters, ['datesStartEnd'])

      return {
        ...state,
        filters,
        tempItems: false,
        dateFilterSelected: false,
      }
    }
    case c.TRANSACTIONS_CLEAR_FILTERS: {
      const { debitCreditFlag } = state.filters
      let filters = {}

      if (debitCreditFlag) {
        filters = {
          debitCreditFlag,
        }
      }

      return {
        ...state,
        filters,
        tempItems: false,
        dateFilterSelected: false,
      }
    }
    case c.LOCATION_CHANGE:
      return {
        ...state,
        filters: {},
        tempItems: false,
      }

    case c.TRANSACTIONS_TEMP_ITEMS_POPULATE_DATE: {
      const tempItemsDate = p.items
      return {
        ...state,
        tempItems: tempItemsDate,
      }
    }
    case c.TEMP_ITEM_MERGE: {
      const oldItems = state.tempItems
      const newTemp = p.items
      const items = uniqBy(unionWith(oldItems, newTemp, isEqual), 'id')
      return {
        ...state,
        tempItems: items,
      }
    }
    case c.UPDATE_FILTER_SHOW_MORE_COUNT_ZERO: {
      return {
        ...state,
        filterShowMoreCount: 0,
      }
    }
    case c.UPDATE_FILTER_SHOW_MORE_COUNT: {
      return {
        ...state,
        filterShowMoreCount: state.filterShowMoreCount + 1,
      }
    }

    default:
      return state
  }
}
