import React from 'react'
import Alert from '../../assets/icons/Alert'
import Calendar from '../../assets/icons/Calendar'
import Check from '../../assets/icons/Check'
import ChevronDoubleLeft from '../../assets/icons/ChevronDoubleLeft'
import ChevronLeft from '../../assets/icons/ChevronLeft'
import ChevronRight from '../../assets/icons/ChevronRight'
import ChevronUp from '../../assets/icons/ChevronUp'
import ChevronDown from '../../assets/icons/ChevronDown'
import Cross from '../../assets/icons/Cross'
import DirectDebit from '../../assets/icons/DirectDebit'
import DocumentIcon from '../../assets/icons/Document'
import Download from '../../assets/icons/Download'
import Flag from '../../assets/icons/Flag'
import Home from '../../assets/icons/Home'
import Hourglass from '../../assets/icons/Hourglass'
import LoadingError from '../../assets/icons/LoadingError'
import Loading from '../../assets/icons/Loading'
import Mail from '../../assets/icons/Mail'
import MakePayment from '../../assets/icons/MakePayment'
import Menu from '../../assets/icons/Menu'
import Payments from '../../assets/icons/Payments'
import Print from '../../assets/icons/Print'
import Search from '../../assets/icons/Search'
import Services from '../../assets/icons/Services'
import TransferMoney from '../../assets/icons/TransferMoney'
import Weatherbys from '../../assets/icons/Weatherbys'

type IconProps = {
  icon?: string
  width?: number
  height?: number
  className?: string
}

const components = {
  alert: Alert,
  calendar: Calendar,
  check: Check,
  chevronDoubleLeft: ChevronDoubleLeft,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  cross: Cross,
  directDebit: DirectDebit,
  document: DocumentIcon,
  download: Download,
  flag: Flag,
  home: Home,
  hourglass: Hourglass,
  loadingError: LoadingError,
  loading: Loading,
  mail: Mail,
  makePayment: MakePayment,
  menu: Menu,
  payments: Payments,
  print: Print,
  search: Search,
  services: Services,
  transferMoney: TransferMoney,
  weatherbys: Weatherbys,
}

/**
note: svg were converted to react components via following script 'npm run svgr'

note: glyph is deprecated - as per MDN,
note: replaced all glyph via svg react components
https://developer.mozilla.org/en-US/docs/Web/SVG/Element/glyph#browser_compatibility
*/

const Icon = ({ icon, width = 16, height = 16, className = 'icon' }: IconProps) => {
  const IconComponent = components[icon]
  return IconComponent ? (
    <IconComponent className={className} width={width} height={height} />
  ) : (
    <></>
  )
}

export default Icon
