import { connect } from 'react-redux'
import PayeeMessage from '../../components/PayeeMessage'
import { showMessage, setShowPayeeMessageFalse } from '../PayeeMessage/actions'

const mapStateToProps = (state) => ({
  showPayeeMessage: state.payeeMessage.showPayeeMessage || false,
})

const mapDispatchToProps = (dispatch) => ({
  onPayeeMessageClick: () => {
    dispatch(showMessage())
  },
  setShowPayeeMessageFalse: () => {
    dispatch(setShowPayeeMessageFalse())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PayeeMessage)
