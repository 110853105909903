import { connect } from 'react-redux'
import { change } from 'redux-form'
import get from 'lodash/get'
import PaymentMakeForm from '../../components/PaymentMakeForm'
import { getFromAccount, getToAccount, getHasReference } from '../PaymentMake/selectors'
import { paymentMakeVerify, paymentMakeVerifySubmit } from '../PaymentMake/actions'
import { modalShow } from '../../store/ui'

const mapStateToProps = (state) => ({
  currency: getFromAccount(state).currency,
  lastReference: getToAccount(state).lastReference,
  initialValues: {
    when: 'now',
    frequencyCode: {
      value: '50',
    },
    repeatUntil: {
      value: 'forever',
    },
    isRegular: get(state, 'routing.locationBeforeTransitions.query.isRegular'),
  },
  isSubmitDisabled: state.ui.panel === 'changeAccount',
  hasReference: getHasReference(state),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  paymentVerify: paymentMakeVerify,
  onClickLastReference: (ref) => {
    dispatch(change('paymentMakeVerify', 'reference', ref))
  },
  checkReference: (e, hasRef) => {
    e.preventDefault()
    if (hasRef) {
      dispatch(paymentMakeVerifySubmit)
    } else {
      dispatch(modalShow('paymentNoReference'))
    }
  },
  onSubmitButtonClick: () => {
    dispatch(paymentMakeVerifySubmit)
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMakeForm)
