import React, { ReactNode } from 'react'
import s from './styles.css'

type FieldLayoutProps = {
  layout?: 'normal' | 'inline'
  label?: string
  subLabel?: ReactNode
  children: ReactNode
  name?: string
  labelPosition?: 'top' | false
}

const FieldLayout = ({
  layout,
  label,
  subLabel,
  children,
  name,
  labelPosition,
}: FieldLayoutProps) => (
  <div className={`${s.root} ${s[layout]} ${labelPosition && s[labelPosition]}`}>
    {label && (
      <label className={s.label} htmlFor={name}>
        {label}
      </label>
    )}
    {subLabel && <span className={s.subLabel}>{subLabel}</span>}
    <div className={s.col}>{children}</div>
  </div>
)

export default FieldLayout
