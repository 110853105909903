import React, { ReactNode } from 'react'
import FieldLayout from '../FieldLayout'
import Input from '../Input'
import ValidationList from '../ValidationList'

import s from './styles.css'

type SingleFieldProps = {
  input: any
  InputComponent?: React.FC
  inputComponentProps?: any
  label?: string
  subLabel?: ReactNode
  meta?: { touched?: any; error?: any; submitFailed?: any }
  type?: string
  placeholder?: string
  layout?: 'normal' | 'inline' // todo - test - was string
  validationMessages?: any
  hideErrors?: boolean
  autoFocus?: boolean
  className?: string
  validateType?: 'onSubmit' | 'onBlur' | 'list' | false
  autoComplete?: string
  maxLength?: number
}

const SingleField = ({
  input,
  type,
  placeholder,
  InputComponent,
  inputComponentProps,
  validationMessages,
  validateType,
  label,
  subLabel,
  layout,
  hideErrors,
  autoFocus,
  autoComplete,
  maxLength,
  className,
  meta: { touched, error, submitFailed },
}: SingleFieldProps) => {
  let showError = error && submitFailed && !hideErrors
  let validationListItems

  const errorMessage = validationMessages ? validationMessages[error] : error

  // When errors are expected to come back as an array,
  // we check them and build up the items prop for ValidationMessages component
  if (validateType === 'list') {
    showError = false
    validationListItems = Object.keys(validationMessages).map((key) => {
      let valid

      if (!error) {
        valid = true
      } else {
        valid = error.indexOf(key) === -1
      }

      return { title: validationMessages[key], valid }
    })
  } else if (validateType === 'onBlur') {
    showError = error && (touched || submitFailed)
  }

  const errorEl = showError ? <span className={s.error}>{errorMessage}</span> : false
  const inputEl = InputComponent ? (
    <InputComponent
      className={className}
      input={input}
      {...inputComponentProps}
      showError={showError}
      autoFocus={autoFocus}
    />
  ) : (
    <Input
      className={className} /* Todo: Not used with-in Input */
      input={input}
      type={type}
      placeholder={placeholder}
      showError={showError}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      maxLength={maxLength}
    />
  )
  return (
    <div className={showError ? 'error' : undefined}>
      <FieldLayout label={label} subLabel={subLabel} name={input.name} layout={layout}>
        {errorEl}
        {inputEl}
      </FieldLayout>
      {validateType === 'list' && <ValidationList items={validationListItems} />}
    </div>
  )
}

export default SingleField
