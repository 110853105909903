import React from 'react'
import s from './styles.css'

type DetailsBlockProps = {
  descriptor?: string
  title?: string
  extras?: string[]
  actionText?: string
  onActionClick?: () => void
  respondMode?: string
}

const DetailsBlock = ({
  descriptor,
  title,
  extras,
  actionText,
  onActionClick,
  respondMode,
}: DetailsBlockProps) => {
  let respond: string
  if (respondMode === 'forceRows') {
    respond = s.forceRows
  } else if (respondMode !== 'forceCols') {
    respond = s.respond
  }
  return (
    <div className={`${s.root} ${respond}`}>
      {descriptor && <span className={`${s.descriptor} ${respond}`}>{descriptor}</span>}
      {/* eslint-disable-next-line react/no-danger */}
      {title && <h3 className={s.title} dangerouslySetInnerHTML={{ __html: title }} />}
      {extras && (
        <ul className={s.extras}>
          {extras.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
      {actionText && (
        <a href='#' onClick={onActionClick} className={s.link}>
          {actionText}
        </a>
      )}
    </div>
  )
}

export default DetailsBlock
