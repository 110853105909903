import React from 'react'
import Button from '../Button'
import Styles from './style.css'

type PayeeMessageProps = {
  loading?: boolean
  page?: string
}

const PayeeMessage = ({ loading, page }: PayeeMessageProps) => {
  let action: string
  let type: string
  let additionalText: string

  if (page === 'addNewPayee') {
    action = 'add'
    type = 'payee'
    additionalText = 'to a new payee'
  } else {
    action = 'make'
    type = 'payment'
    additionalText = ''
  }

  return (
    <div>
      <div className={Styles.fraudMessageDiv}>
        <span className={Styles.heading1}>Fraud Awareness</span>
        <span className={Styles.heading2}>
          We are absolutely committed to try and make sure you are not the victim of payment fraud.
          Therefore before you {action} this {type}, can we ask:
          <ul className={Styles.ulStyles}>
            <li className={Styles.liStyles}>
              Have you verbally checked the {type} details on a known telephone number, if taken
              from an email or text?
            </li>
            <li className={Styles.liStyles}>
              Have you been pressured to {action} this {type}?
            </li>
            <li className={Styles.liStyles}>
              Are you aware of the risks associated with crypto payments and investments?
            </li>
            <li className={Styles.liStyles}>
              If you are being advised by a company have you checked they are on the FCA register?
            </li>
          </ul>
          If in any doubt, please call your Private Bank Team on +44 (0) 1933 543 600 or the Racing
          Bank Team on +44 (0) 1933 543 543 before you proceed.
        </span>
      </div>
      <div className={Styles.fraudAdditionalMessageDiv}>
        <span className={Styles.textPlan}>
          For your security, in some instances a payment {additionalText} may be held whilst we{' '}
          undertake additional checks.
        </span>
      </div>
      <Button text='I WISH TO PROCEED' submit loading={loading} />
    </div>
  )
}

export default PayeeMessage
