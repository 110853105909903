import React from 'react'
import s from './styles.css'
import DetailsBlock from '../DetailsBlock'
import ReactSelect from '../Forms/ReactSelect'

type PaymentMakeFromToProps = {
  onChangeFromAccountClick: () => void
  onChangeFromAccountSelect: () => void
  onChangeToAccountClick: () => void
  fromAccount?: any // todo - was object
  toAccount?: any // todo - was object
  isChangingAccount?: boolean
  fromOptions: any[]
}

const PaymentMakeFromTo = ({
  fromAccount,
  toAccount,
  onChangeFromAccountClick,
  onChangeFromAccountSelect,
  isChangingAccount,
  fromOptions,
  onChangeToAccountClick,
}: PaymentMakeFromToProps) => (
  <div className={s.row}>
    <div className={s.item}>
      {isChangingAccount ? (
        <div className={s.form}>
          <ReactSelect options={fromOptions} onChange={onChangeFromAccountSelect} />
        </div>
      ) : (
        <DetailsBlock
          descriptor='From Account'
          respondMode='forceRows'
          title={fromAccount.title}
          extras={[
            `${fromAccount.sortCode} ${fromAccount.accountNumber}`,
            `Current balance: ${fromAccount.balance}`,
            `Available balance: ${fromAccount.availableBalance}`,
          ]}
          actionText='Change account'
          onActionClick={onChangeFromAccountClick}
        />
      )}
    </div>
    <div className={s.item}>
      {toAccount && (
        <DetailsBlock
          descriptor='To Account'
          respondMode='forceRows'
          title={toAccount.title}
          extras={[`${toAccount.sortCodeNice} ${toAccount.accountNumber}`]}
          actionText='Pay someone else?'
          onActionClick={onChangeToAccountClick}
        />
      )}
    </div>
  </div>
)

export default PaymentMakeFromTo
