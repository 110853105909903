import React from 'react'
import Breadcrumbs from '../Breadcrumbs'
import InnerContainer from '../InnerContainer'
import Form from './Form'
import ActionHeader from '../ActionHeader'
import FlowSwitch from '../../containers/FlowSwitch'
import FlowConfirm from '../../containers/FlowConfirm'

type PayeeEditProps = {
  successComponentProps?: any
}

const PayeeEdit = (props: PayeeEditProps & any) => (
  <div>
    <Breadcrumbs
      items={[
        {
          to: '/payments',
          text: 'Payments',
        },
        {
          to: '/payments/payees',
          text: 'Manage payees',
        },
        {
          to: '/payments/payees/edit',
          text: 'Edit payee',
        },
      ]}
    />

    <InnerContainer>
      <ActionHeader title='Edit payee' />

      <FlowSwitch
        SuccessComponent={FlowConfirm}
        successComponentProps={props.successComponentProps}
        formEl={<Form {...props} />}
      />
    </InnerContainer>
  </div>
)

export default PayeeEdit
