import React from 'react'
import InnerContainer from '../InnerContainer'
import BreadCrumbs from '../Breadcrumbs'
import Form from '../../containers/PaymentsUpcomingEdit/form'
import DetailsBlock from '../DetailsBlock'
import Warnings from '../Warnings'
import s from './styles.css'

type PaymentsUpcomingEditViewProps = {
  props: any // todo - was object.
  from: {
    descriptor: string
    title: any
    extras: string[]
  } // todofixed - was object.
  to: {
    descriptor: string
    title: any
    extras: string[]
  } // todofixed - was object.
  isCustom: boolean
}

const PaymentsUpcomingEditView = ({
  from,
  to,
  isCustom,
  ...rest
}: PaymentsUpcomingEditViewProps) => (
  <InnerContainer>
    <BreadCrumbs
      items={[
        { text: 'Payments & transfers', to: '/payments' },
        { text: 'Standing orders & direct debits', to: '/payments/upcoming' },
        { text: 'Edit recurring payment' },
      ]}
    />
    <h1>Edit Recurring Payment</h1>
    {isCustom ? (
      <Warnings
        items={[
          `Unfortunately, this recurring payment cannot be edited online as it has a custom 
      frequency. To edit this payment, please call the Bank Helpdesk on +44 (0) 1933 543543.`,
        ]}
      />
    ) : (
      <span>
        <div className={s.details}>
          <div className={s.detail}>
            <DetailsBlock {...from} />
          </div>
          <div className={s.detail}>
            <DetailsBlock {...to} />
          </div>
        </div>
        <Form {...rest} />
      </span>
    )}
  </InnerContainer>
)

export default PaymentsUpcomingEditView
