import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as formReducer } from 'redux-form'
import sessionReducer from './session'
import userReducer from './user'
import uiReducer from './ui'
import flowReducer from './flow'
import entitiesReducer from './entities'
import responsiveReducer from './responsive'
import transferMoneyFormReducer from '../containers/TransferMoneyForm/reducer'
import transactionsFiltersFormReducer from '../containers/TransactionsFiltersForm/reducer'
import superDatePickerReducer from '../containers/SuperDatePicker/reducer'
import changeCredentialsReducer from '../containers/ChangeCredentials/reducer'
import mailReducer from '../containers/MailLayout/reducer'
import accountsReducer from '../containers/Accounts/reducer'
import transactionsReducer from '../containers/Transactions/reducer'
import paymentMakeReducer from '../containers/PaymentMake/reducer'
import statementsReducer from '../containers/Statements/reducer'
import payeesReducer from '../containers/Payees/reducer'
import twoFactorAuthReducer from '../containers/TwoFactorAuth/reducer'
import paymentsFutureReducer from '../containers/PaymentsFuture/reducer'
import paymentsRecurringReducer from '../containers/PaymentsRecurring/reducer'
import activityLogReducer from '../containers/ActivityLog/reducer'
import paymentsBulkReducer from '../containers/PaymentsBulk/reducer'
import chequeStatusReducer from '../containers/ChequeStatus/reducer'
import authorisationsReducer from '../containers/Authorisations/reducer'
import alertsReducer from '../containers/Alerts/reducer'
import payeeMessageReducer from '../containers/PayeeMessage/reducer'
import pendingTransactionsTableReducer from '../containers/PendingTransactionsTable/reducer'
import availableBalanceInfoModalReducer from '../containers/AvailableBalanceInfoModal/reducer'
import { IAppState } from './state'

export const makeRootReducer = () =>
  combineReducers<IAppState, any>({
    session: sessionReducer,
    changeCredentials: changeCredentialsReducer,
    user: userReducer,
    routing: routerReducer,
    ui: uiReducer,
    flow: flowReducer,
    browser: responsiveReducer,
    entities: entitiesReducer,
    accounts: accountsReducer,
    transactions: transactionsReducer,
    statements: statementsReducer,
    paymentMake: paymentMakeReducer,
    paymentsFuture: paymentsFutureReducer,
    paymentsRecurring: paymentsRecurringReducer,
    paymentsBulk: paymentsBulkReducer,
    payees: payeesReducer,
    superDatePicker: superDatePickerReducer,
    form: formReducer.plugin({
      transferMoney: transferMoneyFormReducer,
      transactionsFilters: transactionsFiltersFormReducer,
    }),
    twoFactorAuth: twoFactorAuthReducer,
    mail: mailReducer,
    activityLog: activityLogReducer,
    chequeStatus: chequeStatusReducer,
    authorisations: authorisationsReducer,
    alerts: alertsReducer,
    payeeMessage: payeeMessageReducer,
    pendingTransactions: pendingTransactionsTableReducer,
    availableBalanceInfoModal: availableBalanceInfoModalReducer,
  })

export default makeRootReducer
