import { connect } from 'react-redux'
import PayeeFields from '../../components/Forms/PayeeFields'
import { sortCodeValidate } from '../../sagas/sortCodeValidate/actions'
import { getBankName } from './selectors'

const mapStateToProps = (state, ownProps) => ({
  bankName: getBankName(state, ownProps),
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSortCodeChange: () => {
    dispatch(sortCodeValidate(ownProps.formName))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PayeeFields)
