import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import Button from '../Button'
import BottomError from '../Forms/BottomError'
import PayeeFields from '../../containers/PayeeFields'
import Select from '../Forms/Select'
import SingleField from '../Forms/SingleField'
import InputWithTooltip from '../Forms/InputWithTooltip'
import * as v from '../../utils/forms/validators'
import PayeeMessage from '../../components/PayeeMessage'

type PayeeNewFormProps = {
  handleSubmit?: () => void
  payeeNewSubmit?: () => void
  error?: string
  submitting?: boolean
  selectOptions?: any[]
  showPayeeMessage?: boolean
  onPayeeButtonClick?: () => void
  setShowPayeeMessageFalse?: () => void
}

const PayeeNewForm = class PayeeNewForm extends React.Component<
  InjectedFormProps<any> & PayeeNewFormProps
> {
  componentDidMount() {
    this.props.setShowPayeeMessageFalse()
  }

  render() {
    const {
      handleSubmit,
      payeeNewSubmit,
      error,
      submitting,
      selectOptions,
      showPayeeMessage,
      onPayeeButtonClick,
    } = this.props
    return (
      <div>
        <form onSubmit={handleSubmit(payeeNewSubmit)}>
          <fieldset className='narrow'>
            <InputWithTooltip
              tooltip={{
                id: 'newPayeeEntity',
                text: 'Each payee is directly tied to an entity',
              }}
              field={{
                name: 'entity',
                label: 'Entity',
                component: SingleField,
                InputComponent: Select,
                inputComponentProps: {
                  options: selectOptions,
                },
                validate: v.required,
                validationMessages: {
                  required: 'Please select an entity',
                },
              }}
            />
            <PayeeFields formName='payeeNew' />
            <BottomError message={error} />
            {!showPayeeMessage && <Button onClick={onPayeeButtonClick} text='Add Payee' />}
            {showPayeeMessage && <PayeeMessage loading={submitting} page='addNewPayee' />}
          </fieldset>
        </form>
      </div>
    )
  }
}

const PayeeNewReduxForm = reduxForm<any, PayeeNewFormProps>({
  form: 'payeeNew',
})(PayeeNewForm)

export default PayeeNewReduxForm
