import React from 'react'
import { reduxForm, InjectedFormProps } from 'redux-form'
import InnerContainer from '../InnerContainer'
import Button from '../Button'
import BottomError from '../Forms/BottomError'
import ScrollTo from '../../containers/ScrollTo'
import ActionHeader from '../ActionHeader'
import PayeeFields from '../../containers/PayeeFields'
import PayeeMessage from '../../containers/PayeeMessage'

type PaymentMakeNewPayeeProps = {
  handleSubmit: () => void
  payeeSubmit: () => void
  error?: string
  invalid?: boolean
  submitting?: boolean
  onViewPayeesClick: () => void
  onPayeeMessageClick: () => void
  showPayeeMessage?: boolean
}

const PaymentMakeNewPayee = ({
  handleSubmit,
  payeeSubmit,
  error,
  invalid,
  submitting,
  onViewPayeesClick,
  showPayeeMessage,
  onPayeeMessageClick,
}: InjectedFormProps<any> & PaymentMakeNewPayeeProps) => (
  <InnerContainer>
    <ScrollTo>
      <ActionHeader
        title='New payment or transfer'
        actionText='View my payees'
        headerSize='2'
        onClick={onViewPayeesClick}
      />
    </ScrollTo>
    <form onSubmit={handleSubmit(payeeSubmit)}>
      <fieldset className='narrow'>
        <PayeeFields formName='paymentMakeNewPayee' />

        <BottomError message={error} />
        {!showPayeeMessage && (
          <Button
            onClick={() => {
              onPayeeMessageClick()
            }}
            text='Add Payee'
          />
        )}

        {showPayeeMessage && <PayeeMessage loading={submitting} />}
      </fieldset>
    </form>
  </InnerContainer>
)

const PaymentMakeNewPayeeReduxForm = reduxForm<any, PaymentMakeNewPayeeProps>({
  form: 'paymentMakeNewPayee',
  destroyOnUnmount: false,
})(PaymentMakeNewPayee)

export default PaymentMakeNewPayeeReduxForm
