import { connect } from 'react-redux'
import PayeesView from '../../components/PayeesView'
import { flowUpdateData } from '../../store/flow'
import { modalShow } from '../../store/ui'
import { getSelectedPayee } from './selectors'
import { payeeDelete } from './actions'
import { setShowPayeeMessageFalse } from '../PayeeMessage/actions'

const mapStateToProps = (state, ownProps) => ({
  selectedPayee: getSelectedPayee(state),
  actions: [
    {
      text: 'Pay',
      id: 'pay',
      // Link "to" prop
      // We pass a function so that can use row id in the string
      to: (id) => `payments/make?toAccountId=${id}`,
    },
    {
      text: 'Edit',
      id: 'edit',
      to: (id) => `payments/payees/edit/${id}`,
    },
    {
      text: 'Delete',
      id: 'delete',
    },
  ],
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  onActionClick: (actionId, rowId) => {
    if (actionId === 'delete') {
      dispatch(flowUpdateData(rowId))
      dispatch(modalShow('payeeDelete'))
    }
  },
  payeeDelete,
  setShowPayeeMessageFalse: () => {
    dispatch(setShowPayeeMessageFalse())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(PayeesView)
