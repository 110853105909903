import { call, takeLatest, select, put } from 'redux-saga/effects'
import { stopSubmit } from 'redux-form'
import * as a from './actions'
import * as c from '../../constants'
import request from '../../utils/request'
import { formError } from '../../sagas/formErrors/actions'
import { paymentMakeNewPayeeDetailsClear } from '../PaymentMake/actions'
import { flowSuccess } from '../../store/flow'
import { twoFactorAuthSetInitialRequest, twoFactorAuthSetRef } from '../TwoFactorAuth/actions'
// import GA from '../../utils/GA'

export function* handlePayeeNewSubmit({ payload: p }) {
  try {
    yield put(paymentMakeNewPayeeDetailsClear())
    yield put(twoFactorAuthSetInitialRequest('payeeCreate'))
    const nonce = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const details = yield call(request, {
      id: c.requestPayeeNewInit,
      url: 'cz/v1/payments/payeeGroup?locale=en',
      partyId: p.entity.value,
      nonce,
      payload: {
        ...p,
      },
    })
    const nonce1 = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const details1 = yield call(request, {
      id: c.requestPayeeNewInit2,
      url: `cz/v1/payments/payeeGroup/${details}/payees/external?locale=en`,
      partyId: p.entity.value,
      nonce: nonce1,
      payload: {
        ...p,
      },
    })
    const bankDetails = yield select((state: any) => state.paymentMake.newPayeeDetails)
    const nonce2 = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    yield call(request, {
      id: c.requestPayeeNewInit3,
      url: `cz/v1/payments/payeeGroup/${details}/payees/external/${details1.id}?locale=en`,
      partyId: p.entity.value,
      nonce: nonce2,
      payload: {
        stage: 2,
        ...p,
        ...bankDetails,
      },
    })
    const nonce3 = yield call(request, {
      id: c.requestNonce,
      url: 'v1/session/nonce',
    })
    const res = yield call(request, {
      id: c.requestPayeeNew,
      url: `cz/v1/payments/payeeGroup/${details}/payees/external/${details1.id}?locale=en`,
      nonce: nonce3,
      partyId: p.entity.value,
      payload: {
        stage: 2,
        ...p,
        ...bankDetails,
      },
    })
    const payeeDetails = {
      refNumber: res.ref,
      groupId: details,
      payeeId: details1.id,
      partyId: p.entity.value,
    }
    yield put(twoFactorAuthSetRef(payeeDetails))
    yield put(flowSuccess(p))
    // #GA4-PROVISIONALLY-DISABLED
    // GA.event({
    //   category: 'Form',
    //   action: 'Submit',
    //   label: 'Add New Payee',
    // })
  } catch (error) {
    console.error(error)
    yield put(formError(a.payeeNewSubmit.FAILURE, error))
    yield put(stopSubmit('payeeNew'))
  }
}

// eslint-disable-next-line require-yield
export function* handlePayeeAfterAuth(action: any) {
  try {
    const { payload: p } = action
    const { href } = window.location
    if (!href.includes('payments/payees/new')) {
      return
    }
  } catch (error) {
    console.error(error)
  }
}

export function* watchPayeeNew() {
  yield takeLatest(a.payeeNewSubmit.REQUEST, handlePayeeNewSubmit)
  yield takeLatest(c.TWO_FACTOR_AUTH_COMPLETE, handlePayeeAfterAuth)
}
