/* eslint-disable */
// @ts-nocheck
// todo - eslint and typescript
import moment from 'moment'
import * as c from '../../constants'
import { isTrue } from '../common'

/**
 * Constructs request to API based on id
 * to make requests with a simple object
 * @param  {object} request - object with details of request.
 * @param {string} request.id should contain one of the request constants (e.g. requestAccounts)
 * @body = {string} stringified JSON request
 */
export const buildBody = (request, state) => {
  const p = request.payload || {}
  let body = {}
  let method = 'get'
  let contentType = 'application/json'
  if (request.id === undefined) {
    // console.error('Undefined request id, have you missed a constant?')
  }
  switch (request.id) {
    case c.requestLogin1:
      contentType = 'application/x-www-form-urlencoded'
      body = `j_username=${p.user}&j_password=${encodeURIComponent(p.pass)}`
      method = 'post'
      break
    case c.requestLogin2:
      body = {}
      break
    case c.requestLoginFlow:
      body = {}
      break
    case c.requestNonce:
      method = 'post'
      body = {}
      break
    case c.requestNonceAtLogin:
      method = 'post'
      body = {}
      break
    case c.requestPermissions:
      body = {}
      break
    case c.requestLogout:
      method = 'delete'
      body = {}
      break
    case c.requestFirstTimeFlags:
      break
    case c.requestForceChangeCredential:
      method = 'put'
      body = {
        newPassword: p.newPass,
        oldPassword: p.oldPass,
        userId: state.user.id,
      }
      break
    case c.requestUserTransactionLimit:
      break
    case c.requestUserTransactionLimitSpent:
      break
    case c.requestGroupTransactionLimit:
      break
    case c.requestGroupTransactionLimitSpent:
      break
    case c.requestManageCredentials: {
      method = 'put'
      let bodyData = {}
      if (p.type === 'pin') {
        bodyData = {
          changedPin: p.new,
          oldPin: p.old,
        }
      } else {
        bodyData = {
          changedPassword: p.new,
          oldPassword: p.old,
        }
      }
      body = bodyData
      break
    }
    case c.requestNewForceChange: {
      method = 'put'
      let bodyData = {
        newPassword: p.newPassword,
        token: p.token,
      }
      body = bodyData
      break
    }
    case c.requestAccountNameChange: {
      const account = state.accounts.items[p.id]
      const type = account.accountType
      const accountNumber = account.accountId
      const partyId = account.customerId
      const { newName } = p
      method = 'post'
      body = {
        accountNicknameDTOs: [
          {
            accountNickname: newName,
            accountNumber,
            partyId,
            accountType: type,
            sequenceNumber: account.order,
          },
        ],
      }
      break
    }
    case c.requestPayeeDetails: {
      break
    }
    case c.requestAcceptTerms:
      method = 'post'
      body = {
        loginConfigId: p.loginConfigId,
      }
      break
    case c.requestFirstTimeSkipStep:
      break
    case c.requestFirstTimeComplete:
      break
    case c.requestAccounts:
      body = ''
      break
    case c.requestEntities:
      body = {
        fldRequestId: '_ENTITIES',
      }
      break
    case c.requestLastTransaction: {
      break
    }
    case c.requestTransactions: {
      body = ''
      break
    }
    case c.requestLoanTransactions: {
      body = ''
      break
    }
    case c.requestTermDepositTransactions: {
      body = ''
      break
    }
    case c.requestLoanDetails: {
      body = ''
      break
    }
    case c.requestLoanDetailsPrincipalbalance: {
      break
    }
    case c.requestTermDepositDetails: {
      body = ''
      break
    }
    case c.requestAccountCustomerAddress: {
      body = ''
      break
    }
    case c.requestStatements: {
      break
    }
    case c.requestStatementDownload: {
      break
    }
    case c.requestPaymentMapping: {
      break
    }
    case c.requestStatementsMapping: {
      break
    }
    case c.requestPaymentsBulkMapping: {
      break
    }
    case c.requestChequeBookMapping: {
      break
    }
    case c.reqChqStatMapp: {
      break
    }
    case c.requestPayInBookMapping: {
      break
    }
    case c.requestAlertsMapping: {
      break
    }
    case c.requestUserAlerts: {
      body = ''
      break
    }
    case c.requestAlertsUpload: {
      const EMAIL = 'EMAIL'
      const SMS = 'SMS'
      const PARTY = 'PARTY'
      const CUSTOMER = 'CUSTOMER'
      const URGENCY = 'H'
      const today = moment().format('YYYY-MM-DD')
      const { partyId } = p
      const subscribedActions = []
      const subscriptionPreferences = []
      const subscriptionPreferencesF = []
      const subscriptionPreferencesS = []
      let subscribedAction = {}
      let subscriptionPreference = {}
      if (p.userAlerts.length !== 0) {
        let destination = ''
        let alertName = ''
        let activityId = ''
        let eventId = ''
        let actionId = ''
        let moduleId = ''
        let keyDTOId = ''
        p.userAlerts.map((loginItem) => {
          if (loginItem === 'loginFailure') {
            moduleId = p.alertIds[0].module
            actionId = p.alertIds[0].actionId
            activityId = p.alertIds[0].activityId
            eventId = p.alertIds[0].alertId
            alertName = p.alertIds[0].alertDesc
            keyDTOId = p.alertIds[0].keyDTO.id
            subscribedAction = {
              module: moduleId,
              actionId,
              activityId,
              eventId,
              subscriptionId: p.subId,
              subscribedActionStartDate: today,
              subscribedActionEndDate: '2099-10-04',
              alertName,
              subscriptionPreferences,
              actionTemplate: {
                keyDTO: {
                  id: keyDTOId,
                },
              },
              subscriptionLevel: PARTY,
              subscriptionLevelPartyKey: partyId,
            }
            subscribedActions.push(subscribedAction)
            p.userFailed.map((item) => {
              subscriptionPreference = ''
              if (item === 'email') {
                destination = EMAIL
              } else {
                destination = SMS
              }
              subscriptionPreference = {
                recipientCategory: PARTY,
                recipient: CUSTOMER,
                startRestrictedTime: 0,
                endRestrictedTime: 0,
                transactionAmount: {
                  currency: 'NA',
                  amount: 0,
                },
                consolidationRequired: false,
                timeRestricted: false,
                destination,
                urgencyType: URGENCY,
                subscriptionId: p.subId,
                subscriptionLevel: PARTY,
                subscriptionLevelPartyKey: partyId,
                activityId,
                eventId,
                actionId,
                subscriberValue: CUSTOMER,
              }
              if (subscribedAction.alertName === 'Failed Login Alert') {
                subscriptionPreferencesF.push(subscriptionPreference)
              }
              subscriptionPreference = ''
            })
            if (subscribedActions.length === 1) {
              subscribedActions[0].subscriptionPreferences = subscriptionPreferencesF
            } else {
              subscribedActions[1].subscriptionPreferences = subscriptionPreferencesF
            }
          }
          if (loginItem === 'loginSuccess') {
            moduleId = p.alertIds[1].module
            actionId = p.alertIds[1].actionId
            activityId = p.alertIds[1].activityId
            eventId = p.alertIds[1].alertId
            alertName = p.alertIds[1].alertDesc
            keyDTOId = p.alertIds[1].keyDTO.id
            subscribedAction = ''
            subscribedAction = {
              module: moduleId,
              actionId,
              activityId,
              eventId,
              subscriptionId: p.subId,
              subscribedActionStartDate: today,
              subscribedActionEndDate: '2099-10-04',
              alertName,
              subscriptionPreferences,
              actionTemplate: {
                keyDTO: {
                  id: keyDTOId,
                },
              },
              subscriptionLevel: PARTY,
              subscriptionLevelPartyKey: partyId,
            }
            subscribedActions.push(subscribedAction)
            p.userLogin.forEach((item) => {
              subscriptionPreference = ''
              if (item === 'email') {
                destination = EMAIL
              } else {
                destination = SMS
              }
              subscriptionPreference = ''
              subscriptionPreference = {
                recipientCategory: PARTY,
                recipient: CUSTOMER,
                startRestrictedTime: 0,
                endRestrictedTime: 0,
                transactionAmount: {
                  currency: 'NA',
                  amount: 0,
                },
                consolidationRequired: false,
                timeRestricted: false,
                destination,
                urgencyType: URGENCY,
                subscriptionId: p.subId,
                subscriptionLevel: PARTY,
                subscriptionLevelPartyKey: partyId,
                activityId,
                eventId,
                actionId,
                subscriberValue: CUSTOMER,
              }
              if (subscribedAction.alertName === 'Login Alert') {
                subscriptionPreferencesS.push(subscriptionPreference)
              }
            })
            if (subscribedActions.length === 1) {
              subscribedActions[0].subscriptionPreferences = subscriptionPreferencesS
            } else {
              subscribedActions[1].subscriptionPreferences = subscriptionPreferencesS
            }
          }
        })
      }
      method = p.method
      body = {
        subscriptionId: p.subId ? p.subId : null,
        isActive: true,
        subscriptionLevel: PARTY,
        subscriptionStartDate: today,
        subscriptionEndDate: '2099-02-01',
        subscriber: {
          party: partyId,
          subscriberType: PARTY,
        },
        subscribedActions,
      }
      break
    }
    case c.requestAccountAlerts: {
      // At the moment is not used
      body = {
        fldRequestId: 'RRALR02',
        fldServiceType: 'ALR',
        fldalerttype: 'A',
        fldselidentity: 'B001',
        fldselusertype: 'ECU',
        fldcustno: p.customerId,
        fldacctno: `${p.accountNo}~${p.customerId}~E~${p.branchCode}`,
      }
      break
    }
    case c.requestPayeeGroup: {
      body = ''
      break
    }
    case c.requestPayees: {
      body = ''
      break
    }
    case c.requestPayeeDelete: {
      method = 'delete'
      body = ''
      break
    }
    case c.requestFuturePayments: {
      body = ''
      break
    }
    case c.requestPayeeNewInit: {
      method = 'post'
      body = {
        name: p.name,
      }
      break
    }
    case c.requestPayeeNewInit2: {
      const accNo = p.accountNumber
      method = 'post'
      body = {
        domesticPayeeType: 'UK',
        nickName: p.name,
        payeeType: null,
        status: 'ACT',
        ukDomesticPayee: {
          accountName: p.name,
          accountNumber: accNo,
          bankDetails: {
            address: null,
            branch: null,
            city: null,
            code: p.sortCode,
            codeType: null,
            country: null,
            name: null,
          },
          shared: true,
          network: 'SORT',
          paymentType: 'FAS',
        },
      }
      break
    }
    case c.requestPayeeNewInit3: {
      break
    }
    case c.requestPayeeNew: {
      body = ''
      method = 'patch'
      body = {
        ...body,
      }
      break
    }
    case c.requestFuturePaymentsStop: {
      method = 'post'
      body = {
        instructionType: 'NONREC',
      }
      break
    }
    case c.requestFuturePaymentsStopPatch: {
      method = 'patch'
      body = {
        instructionType: 'NONREC',
      }
      break
    }
    case c.requestDirectDebitStop: {
      method = 'post'
      body = {
        instructionType: 'REC',
      }
      break
    }
    case c.requestDirectDebitStopPatch: {
      method = 'patch'
      body = {
        instructionType: 'REC',
      }
      break
    }
    case c.requestStandingOrderStop: {
      method = 'post'
      body = {
        instructionType: 'REC',
      }
      break
    }
    case c.requestStandingOrderStopPatch: {
      method = 'patch'
      body = {
        instructionType: 'REC',
      }
      break
    }
    case c.requestPayeeCreateOrModify: {
      method = 'put'
      body = {
        domesticPayeeType: 'UK',
        payeeName: p.name,
        nickName: p.name,
        payeeType: null,
        status: 'ACT',
        ukDomesticPayee: {
          accountName: p.name,
          accountNumber: p.accountNumber,
          shared: true,
          network: 'SORT',
          paymentType: 'FAS',
          bankDetails: {
            address: null,
            branch: null,
            city: null,
            code: p.sortCode,
            codeType: null,
            country: null,
            name: null,
          },
        },
      }
      break
    }
    case c.requestTwoFactorAuth: {
      const type = p.requestType
      let bodyData = ''
      method = p.method
      if (type === 'bulkUpload') {
        bodyData = p.bulkData
      } else if (type === 'payeeModify') {
        bodyData = p.editPayeeData
      }
      body = bodyData
      break
    }
    case c.requestVerifySortCode: {
      body = ''
      break
    }
    case c.requestTransferMapping: {
      body = ''
      break
    }
    case c.requestPaymentsRecurringMapping: {
      body = ''
      break
    }
    case c.requestStandingOrders: {
      body = ''
      break
    }
    case c.requestDirectDebit: {
      body = ''
      break
    }
    case c.requestTransferOrPaymentGet: {
      body = ''
      break
    }
    case c.requestTransferOrPaymentPatch: {
      method = 'patch'
      body = {}
      break
    }
    case c.requestTransferOrPayment: {
      const details = (id) => {
        const account = state.accounts.items[id]
        let accountDetails = {}
        if (account) {
          curr = account.currency
          custid = account.customerId

          const accno = account.accountId
          const { branchCode } = account
          const bal = parseFloat(account.availableBalancePure).toFixed(6)
          accountDetails = {
            accno,
            bal,
            curr,
            custid,
            branchCode,
          }
        }
        return accountDetails
      }

      let curr
      let custid
      let toAccount
      let creditAccount
      let payeeId
      let creditAccountId
      let network
      let purposeText
      let purpose
      let dealId
      let charges
      let type
      let frequency
      const debitAccount = details(p.from)
      let startDate
      let endDate =
        p.repeatUntil === 'endDate' ? moment(p.endDate, 'DD/MM/YY').format('YYYY-MM-DD') : undefined
      if (p.when === 'later') {
        startDate = moment(p.laterDate, 'DD/MM/YY').format('YYYY-MM-DD')
      } else {
        startDate = undefined
      }

      if (p.isRegular) {
        type = 'REC'
        frequency = p.frequencyCode
        if (p.when === 'now') {
          startDate = moment().add('1', 'day').format('YYYY-MM-DD')
        }
        if (p.repeatUntil !== 'endDate') {
          endDate = ''
        }
      } else if (!p.isRegular && !p.later) {
        type = undefined
      } else {
        type = 'NONREC'
        endDate = ''
        frequency = '10'
      }

      if (p.type === 'payment') {
        if (p.isNewPayee) {
          payeeId = p.to
        } else {
          toAccount = state.payees.list.items[p.to]
          payeeId = toAccount.id
        }
        network = ''
        charges = ''
      } else if (p.type !== 'payment') {
        creditAccount = details(p.to)
        toAccount = state.accounts.items[p.to]
        creditAccountId = {
          displayValue: 'SELF',
          value: creditAccount.accno,
        }
        if (!p.isRegular) {
          purpose = null
          purposeText = null
          dealId = null
        }
      }

      method = 'post'
      body = {
        amount: {
          amount: p.amount,
          currency: debitAccount.curr,
        },
        creditAccountId,
        payeeId,
        debitAccountId: {
          value: debitAccount.accno,
        },
        network,
        charges,
        remarks: p.reference,
        dealId,
        purpose,
        purposeText,
        startDate,
        endDate,
        type,
        frequency,
      }
      break
    }
    case c.requestAccountDetails: {
      body = ''
      break
    }
    case c.requestMail: {
      break
    }
    case c.requestBulletins: {
      break
    }
    case c.requestBulletinDetail: {
      break
    }
    case c.requestMailSend: {
      method = 'post'
      let bodyData = {
        contentDTO: [],
        messageBody: p.messageBody,
        messageType: p.messageType,
        party: {
          value: p.party,
        },
        subject: p.subject,
        subjectId: p.subjectId,
      }
      if (p.reply) {
        bodyData = {
          contentDTO: [],
          expiryDate: null,
          linkedParent: {
            messageBody: p.parentDetails.messageBody,
            messageId: p.messageId,
            messageType: p.messageType,
            subject: p.parentDetails.subject,
            subjectId: p.subjectId,
          },
          messageBody: p.messageBody,
          messageId: p.messageId,
          messageType: p.messageType,
          subject: p.subject,
          subjectId: p.subjectId,
        }
      }
      body = bodyData
      break
    }
    case c.requestMailDetail: {
      body = ''
      break
    }
    case c.requestUpdateMailStatus: {
      method = 'put'
      body = {
        messageId: {
          value: p.id,
        },
        messageUserMappings: [
          {
            status: 'R',
            deleteStatus: false,
          },
        ],
      }
      break
    }
    case c.requestUpdateBulletinsStatus: {
      method = 'put'
      body = {
        messageId: p.id,
        status: 'R',
        mapId: p.mapId,
      }
      break
    }
    case c.requestActivityLogTypes: {
      break
    }
    case c.requestPendingApprovals: {
      break
    }
    case c.requestActivityLogType: {
      body = ''
      break
    }
    case c.requestActivityLogSingle: {
      body = ''
      break
    }
    case c.requestAuthorisationProcess: {
      method = 'post'
      body = ''
      break
    }
    case c.requestBulkId: {
      break
    }
    case c.requestPaymentsBulkSearch: {
      break
    }
    case c.requestPaymentsBulkItem: {
      break
    }
    case c.requestPaymentsBulkErrorLog: {
      break
    }
    case c.requestPaymentsBulkDownloadFile: {
      break
    }
    case c.requestPaymentsBulkUpload: {
      const account = state.accounts.items[p.accountId]
      const partyId = account.customerId
      const customerId = account.customerId
      const { bulkId } = state.user.bulkId.items[partyId]
      const { accountId } = account
      const { fileName } = p
      method = 'post'
      body = {
        fileName,
        fileContent: p.fileData,
        fileIdentifier: bulkId,
        debitAccountId: accountId,
        customerId,
        valueDate: moment(p.date, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      }
      break
    }
    case c.requestAccountServicesDeliverBook: {
      method = 'post'
      body = {
        acctNo: p.accNo,
      }
      break
    }
    case c.requestChequeStatus: {
      break
    }
    case c.requestSubscribedUserAlerts: {
      break
    }
    case c.requestAccountOrderChange: {
      const { items, itemsOrder } = p
      const order = itemsOrder.reduce((acc, val, idx) => {
        acc.push({
          accountNickname: items[val].title,
          accountNumber: items[val].accountId,
          accountType: items[val].accountType,
          partyId: items[val].customerId,
          sequenceNumber: idx + 1,
        })
        return acc
      }, [])
      method = 'post'
      body = {
        accountNicknameDTOs: order,
      }
      break
    }
    case c.requestPublicKey:
      break

    case c.requestSalt:
      method = 'post'
      body = {}
      break
    case c.requestApprovalCount: {
      break
    }
    case c.requestAuthTokenVerification: {
      break
    }
    case c.requestPasswordPolicy: {
      break
    }

    default:
      console.error('Unexpected request id', request.id)
      break
  }

  if (!isTrue(process.env.__PROD__) && !isTrue(process.env.__TEST__)) {
    // console.info('API request', body)
  }

  const obj = {
    body,
    contentType,
    method,
  }
  return obj
}
class ConstructRequest {
  injectStore(store) {
    this.store = store
  }

  build(request) {
    return buildBody(request, this.store.getState())
  }
}

export default new ConstructRequest()
