import { connect } from 'react-redux'
import PayeeEdit from '../../components/PayeeEdit'
import { payeeSubmit } from '../Payees/actions'
import getPayee from '../../selectors/getPayee'
import formatSortCode from '../../utils/formatSortCode'

export const mapStateToProps = (state, ownProps) => {
  const payee = getPayee(state, ownProps.params.id)
  const flowData = state.flow.data || {}

  return {
    initialBankName: payee.bankName,
    initialValues: {
      id: payee.id,
      name: payee.title,
      sortCode_0: payee.sortCode.substring(0, 2),
      sortCode_1: payee.sortCode.substring(2, 4),
      sortCode_2: payee.sortCode.substring(4, 6),
      accountNumber: payee.accountNumber,
    },
    successComponentProps: {
      confirmIsVisible: false,
      title: 'Payee details',
      successMessage: 'Payee updated',
      successLinks: [
        {
          text: 'Pay this payee',
          to: `/payments/make?toAccountId=${flowData.id}`,
        },
        {
          text: 'Back to my payees',
          to: '/payments/payees',
        },
      ],
      details: [
        {
          descriptor: 'Payee name',
          title: flowData.name,
        },
        {
          descriptor: 'Sort code',
          title: flowData.sortCode && formatSortCode(flowData.sortCode),
        },
        {
          descriptor: 'Account number',
          title: flowData.accountNumber,
        },
      ],
    },
  }
}

export const mapDispatchToProps = (state) => ({
  payeeSubmit,
})

export default connect(mapStateToProps, mapDispatchToProps)(PayeeEdit)
