import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'

import createSagaMiddleware from 'redux-saga'

import { responsiveStoreEnhancer } from 'redux-responsive'
import { persistStore, autoRehydrate } from 'redux-persist'
import { asyncSessionStorage } from 'redux-persist/storages'

import makeRootReducer from './reducers'
import rootSaga from '../sagas/rootSaga'

import constructRequest from '../utils/request/constructRequest'
import tidyResponse from '../utils/tidyResponse'
import refresh from '../utils/refresh'
import goOutMultiLogin from '../utils/goOutMultiLogin'

const sagaMiddleware = createSagaMiddleware()

export default (initialState = {}) => {
  // ======================================================
  // Middleware Configuration
  // ======================================================
  const middleware = [sagaMiddleware]

  // ======================================================
  // Store Enhancers
  // ======================================================

  const enhancers = [responsiveStoreEnhancer, autoRehydrate()]

  const composeEnhancers = composeWithDevTools({})

  return new Promise((resolve, reject) => {
    try {
      const store = createStore(
        makeRootReducer(),
        initialState,
        composeEnhancers(applyMiddleware(...middleware), ...enhancers)
      )

      persistStore(
        store,
        {
          storage: asyncSessionStorage,
          blacklist: ['form', 'ui', 'browser', 'superDatePicker', 'paymentMake', 'twoFactorAuth'],
        },
        () => resolve(store)
      )

      sagaMiddleware.run(rootSaga)

      constructRequest.injectStore(store)
      tidyResponse.injectStore(store)
      refresh.injectStore(store)
      goOutMultiLogin.injectStore(store)
    } catch (e) {
      reject(e)
    }
  })
}
